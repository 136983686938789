<template>
  <div class="" style="height: 600px; overflow: scroll">
    <!-- {{list}} -->
    <div
      class="col-12 lg:col-6 xl:col-12"
      v-for="(data, key) in list"
      :key="key"
    >
      <!-- <div  > -->
      <br />
      <div
        class="card mb-0"
        style="color:white;background-color: #274461; font-size: 12px; line-height: 0.9"
      >
        <!-- {{data}} -->
        <div class="grid">
          <div style="text-align: right" class="col-12 md:col-12">
            <span>Status : </span><b>{{ data.status }}</b>
          </div>

          <div  class="col-12 md:col-2">
            <img v-if="data.vehicledetails[0].documents"
              style="max-width: 70px; height: 70px; border-radius: 50%"
              :src="
                data.vehicledetails[0].documents['vehicle_photos'] != ''
                  ? data.vehicledetails[0].documents['vehicle_photos'][0]
                  : 'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o='
              "
            />
            <img v-else style="max-width: 70px; height: 70px; border-radius: 50%" src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o=">
          </div>
          <div class="grid col-12 md:col-10">
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Owner Name : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <span style="text-align: right"
                  ><b v-if="data.owner_name">{{ data.owner_name.toUpperCase() }}</b></span
                >
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Owner Mobile Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data.owner_mobile_number }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Model : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data.vehicledetails[0].model }}</b>
              </div>
            </div>
            <!-- <div class="col-6 md:col-6">
							<span>Model : </span><b>{{data.vehicledetails[0].model}}</b>
						</div> -->
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Make : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data.vehicledetails[0].make }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Reg Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data.vehicledetails[0].registration_number }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Rc Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data.vehicledetails[0].rc_number }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Fuel type : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data.vehicledetails[0].fuel_type }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Fitness Validity : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{
                  D_M_Y_formatDate(data.vehicledetails[0].fitness_validity)
                }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>PUC Validity : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{
                  D_M_Y_formatDate(data.vehicledetails[0].puc_validity)
                }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Insurance Validity : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{
                  D_M_Y_formatDate(data.vehicledetails[0].insurance_validity)
                }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>BCNT : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ D_M_Y_formatDate(data.vehicledetails[0].bcnt) }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-12">
              <div class="col-12 md:col-6" style="text-align: right">
                <Button @click="open(data)" label="View Documents" class="p-button-warning p-button-sm" />
              </div>
              <div class="col-12 md:col-6" style="text-align: right" v-if="user_type=='Driver' || user_type=='Driver Owner'">
              				<ConfirmPopup></ConfirmPopup>

                    <Button v-if="data.status!='Active'" ref="popup" @click="confirm_activate_driver($event,data)"  label="Mark Active" class="p-button-primary p-button-sm" />
              </div>
              <div class="col-12 md:col-6" style="text-align: right" v-if="user_type=='Owner' || user_type=='Driver Owner'">
                <Button @click="open1(data)" label="View Drivers" class="p-button-warning p-button-sm" />
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Vehicle Details"
          v-model:visible="display"
          :breakpoints="{ '960px': '75vw' }"
          :style="{ width: '50vw' }"
          :modal="true"
        >
          <!-- <p class="line-height-3 m-0"> -->
            <!-- {{vehicle_documents}} -->
            <br>
            <div class="grid"  v-for="(data,key) in vehicle_documents" :key="key">
                <div class="col-3 md:col-3" style="">
                    <span ><b>{{key.replace('_'," ").toUpperCase()}}</b></span>
                </div>
                <div class="col-9 md:col-9" >
                    <!-- {{data}} -->
                    <span v-for="(data1,key1) in data" :key="key1" class="mr-5">
                        <img :src="data1" @click="toggle($event,data1)"  style="width:50px;height:50px" />
                        <!-- {{image_path}} -->
                    </span>
                </div>
            </div>
            
                        <OverlayPanel ref="op" appendTo="body"  :showCloseIcon="true">
							<a :href="image_path" target="_blanl"><img :src="image_path" alt="Nature 9" style="width:300px" /></a>
						</OverlayPanel>
          <template #footer>
            <Button
              label=""
              @click="close"
              icon="pi pi-check"
              class="p-button-outlined"
            />
          </template>
        </Dialog>
        <Dialog
          header="Add Driver Details"
          v-model:visible="display3"
          :breakpoints="{ '960px': '75vw' }"
          :style="{ width: '30vw' }"
          :modal="true"
        >
        <div class="grid col-12 md:col-12" >
          <div class="grid">
            <div class="col-6 md:col-6">
          				Shift time from :
                  
          				
            </div>
            <div class="col-6 md:col-6" style="text-align:right;align:right">
          				<Calendar :showIcon="true" :showDate="false" :showTime="true"
                  hourFormat="12" :timeOnly="true" :showButtonBar="true" v-model="shift_time_from"></Calendar>

            </div>
            <div class="col-6 md:col-6">
          				Shift time to :
            </div>
            <div class="col-6 md:col-6" style="text-align:right;align:right">
          				
          				<Calendar :showIcon="true" :showDate="false" :showTime="true"
                  hourFormat="12" :timeOnly="true" :showButtonBar="true" v-model="shift_time_to"></Calendar>
            </div>
            <div class="col-6 md:col-6">

            </div>
          </div>
          <loading
            :active="isLoadingModel"
            :can-cancel="true"
            
            color="#274461"
            :is-full-page="fullPage"
          ></loading>
        </div>
        <template #footer>
          <Button label="Add" icon="pi pi-plus"  class="p-button-primary p-button-sm" @click="add_driver()"/>

            <Button
              label="Close"
              @click="close3"
              icon="pi pi-check"
              class="p-button-outlined p-button-sm"
            />
          </template>
        </Dialog>
        <Dialog
          header="Driver Details"
          v-model:visible="display1"
          :breakpoints="{ '960px': '75vw' }"
          :style="{ width: '70vw' }"
          :modal="true"
        >
        <div class="grid col-12 md:col-12" style="text-align:right;align:right">
                 <div class="col-12 md:col-12" style="text-align:right;align:right"> 
                    <Button  @click="open2" label="Add New Driver" class="p-button-primary p-button-sm" />
                 </div> 
            </div>
            
        <div
        class=""
        
      >
      <!-- {{driver_list}} -->
        <!-- {{data}} -->
        <div class="col-12 md:col-12" v-if="search_loading1">
          <img src="https://gifimage.net/wp-content/uploads/2018/11/vehicle-loading-gif-8.gif" style="height:80px;width:100%" />
        </div>
        
        <div style="background-color: #274461; font-size: 12px; line-height: 0.9;" class="card mb-0 grid mt-2" v-for="(data1,key) in driver_list" :key="key">
            <!-- {{driver_list}} -->
          <div style="text-align: right" class="col-12 md:col-12" >
            <span>Status : </span><b>{{ data1.status }}</b>
            <!-- {{data1}} -->
          </div>
            
          <div class="col-12 md:col-2">
            <img
              style="max-width: 200px; height: 200px; border-radius: 40%"
              :src="
                data1.driver_photo != ''
                  ? data1.driver_photo
                  : 'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o='
              "
            />
          </div>
          <div class="grid col-12 md:col-10">
          <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Driver Name : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <span style="text-align: right"
                  ><b v-if="data1.driver_name">{{ data1.driver_name }}</b></span
                >
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Mobile Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.driver_mobile_number }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Email id : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.driver_email_id }}</b>
              </div>
            </div>
            <!-- <div class="col-6 md:col-6">
							<span>Model : </span><b>{{data.vehicledetails[0].model}}</b>
						</div> -->
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Gender : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.gender }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>DOB : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dob }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>DL Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dl_number }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Dl Expiry Date : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dl_expiry_date }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>accidental_history </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{
                  data1.accidental_history
                }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>24/7 Avaibility </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{
                  data1.available24by7
                }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Shift time</span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{
                  data1.shift
                }}</b>
              </div>
            </div>
            <!-- <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Shift time to</span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{
                  data1.shift_time_to
                }}</b>
              </div>
            </div> -->
            <div class="grid col-12 md:col-12">
              <div class="col-6 md:col-6">
                <span>Accidental Discription : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{
                  data1.accidental_discription
                }}</b>
              </div>
            </div>
            <!-- <div class="grid col-12 md:col-12" style="text-align:right;align:right">
                 <div class="col-12 md:col-12" style="text-align:right;align:right"> 
                  				<ConfirmPopup></ConfirmPopup>

                    <Button v-if="data1.status!='Active'" ref="popup" @click="confirm_activate_driver($event,data1)"  label="Mark Active" class="p-button-primary p-button-sm" />
                 </div> 
            </div> -->
            
          </div>
        </div>
        </div>
          <!-- <p class="line-height-3 m-0"> -->
            <!-- {{vehicle_documents}} -->
            <br>
            
          <template #footer>
            <Button
              label="Close"
              @click="close"
              icon="pi pi-check"
              class="p-button-outlined"
            />
          </template>
        </Dialog>
        <Dialog
          header="Add New Driver"
          v-model:visible="display2"
          :breakpoints="{ '960px': '75vw' }"
          :style="{ width: '70vw' }"
          :modal="true"
        >
            
        <div
        class="card mb-0"
        style=" font-size: 12px; line-height: 0.9"
      >
      <div class="grid">
        <div class="col-6 md:col-6">
          
      <InputText type="text" v-model="search_number" style="width:100%" placeholder="Enter Mobile Number" />
              
        </div>
        <div class="col-6 md:col-6">
            <Button label="Search"  class="p-button-primary p-button-sm" @click="search_driver()"/>

        </div>
      </div>
      <!-- {{driver_list}} -->
        <!-- {{data}} -->
        <div class="col-12 md:col-12" v-if="search_loading">
          <img src="https://gifimage.net/wp-content/uploads/2018/11/vehicle-loading-gif-8.gif" style="height:80px;width:100%" />
        </div>
        <br>
        <!-- {{search_status}} -->
        <div class="col-12 md:col-12" v-if="search_status==true">
            No Details Found
        </div>
        <div style="background-color: #274461; font-size: 12px; line-height: 0.9" class="card mb-0 grid" v-for="(data1,key) in driver_list1" :key="key">
            <!-- {{driver_list}} -->
          <div style="text-align: right" class="col-12 md:col-12" >
            <span>Status : </span><b>{{ data1.status }}</b>
            <!-- {{data1}} -->
          </div>
            
          <div class="col-12 md:col-2">
            <img
              style="max-width: 70px; height: 70px; border-radius: 50%"
              :src="
                data1.profile_image != ''
                  ? data1.profile_image
                  : 'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o='
              "
            />
          </div>
          <div class="grid col-12 md:col-10">
          <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Driver Name : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <span style="text-align: right"
                  ><b v-if="data1.first_name">{{ data1.first_name.toUpperCase() }}</b> <b v-if="data1.last_name">{{ data1.last_name.toUpperCase() }}</b></span
                >
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Mobile Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.mobile_number }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Email id : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.email_id }}</b>
              </div>
            </div>
            <!-- <div class="col-6 md:col-6">
							<span>Model : </span><b>{{data.vehicledetails[0].model}}</b>
						</div> -->
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Gender : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.gender }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>DOB : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dob }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>DL Number : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dldetails.dl_number }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Dl Expiry Date : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dldetails.dl_expiry_date }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>accidental_history </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dldetails.accidental_history }}</b>
              </div>
            </div>

            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>24/7 Avaibility </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dldetails.available24by7 }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Shift time from</span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dldetails.shift_time_from }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-6">
              <div class="col-6 md:col-6">
                <span>Shift time to</span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dldetails.shift_time_to }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-12">
              <div class="col-6 md:col-6">
                <span>Accidental Discription : </span>
              </div>
              <div class="col-6 md:col-6" style="text-align: right">
                <b>{{ data1.dldetails.accidental_discription }}</b>
              </div>
            </div>
            <div class="grid col-12 md:col-12">
              <div class="col-12 md:col-12" style="text-align:right">
                  <Button  label="Add Driver" @click="add_driver_modal($event,data1)" class="p-button-primary p-button-sm" />
              </div>
            </div>
            
          </div>
        </div>
        </div>
          <!-- <p class="line-height-3 m-0"> -->
            <!-- {{vehicle_documents}} -->
            <br>
            
          <template #footer>
            <Button
              label="Close"
              @click="close2"
              icon="pi pi-check"
              class="p-button-outlined"
            />
          </template>
        </Dialog>
        <!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
      </div>
      <!-- </div> -->
    </div>

    <div class="col-12" v-if="list == ''" style="text-align: left">
      <br />
      Data Not Found
    </div>
  </div>
</template>

<script>
import apis from "@/apis";
export default {
  data() {
    return {
    //   array_display_doc:["PAN CARD","AADHAR CARD","RC","INSURANACE","PUC","VEHICLE PHOTOS"],
      display: false,
      display1: false,
      display2:false,
      display3:false,
      search_loading:false,
      search_loading1:false,
      vehicle_details:[],
      vehicle_documents:[],
      image_path:'',
      driver_list:'',
      driver_list1:'',
      owner_id:'',
      vehicle_id:'',
      driver_id:'',
      search_number:'',
      search_status:false,
      shift_time_from:'',
      shift_time_to:'',
      isLoadingModel:false
    };
  },
  props: ["list", "user_id","user_type"],
  methods: {
    activate_driver()
    {
     var data={
            driver_id:this.driver_id,
            vehicle_id:this.vehicle_id};
            var promise =  apis.activateVehicleDriver(data);
            promise
              .then(response => {
                this.isLoadingModel=false;
                  if(response.data.status==true)
                  {
                    this.$toast.add({
                      severity: "success",
                      summary: "Success",
                      detail: response.data.data,
                      life: 3000,
                    });
                    for(var i=0;i<this.list.length;i++)
                    {
                      // alert('here');
                      if(this.list[i]['vehicle_id']==this.vehicle_id)
                      {
                        this.list[i]['status']='Active';
                      }
                      else
                      {
                        this.list[i]['status']='In Active';
                      }
                    }
                    // this.vehicleDriverList();
                    // this.display3=false;
                    // this.display2=false;
                  }
                  else
                  {
                    this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: response.data.data,
                    life: 3000,
                  });
                  // return false;
                  }
                  
                }
              ) 
    },
    confirm_activate_driver(event,data)
    {
      console.log(data);
      this.driver_id=data.driver_id;
      this.vehicle_id=data.vehicle_id;
      this.$confirm.require({
					target: event.currentTarget,
					message: 'Are you sure you want to proceed?',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
            this.activate_driver();
						// this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
					},
					reject: () => {
						// this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
					}
				});
    },
   async add_driver()
    {
      this.isLoadingModel=true;
      var data={user_id:this.owner_id,
            driver_id:this.driver_id,
            shift:this.time_formatDate(this.shift_time_from) + " - " +this.time_formatDate(this.shift_time_to),
            vehicle_id:this.vehicle_id};
            console.log(data);
            var promise =  apis.addDriverVehicle(data);
            promise
              .then(response => {
                this.isLoadingModel=false;
                  if(response.data.status==true)
                  {
                    this.$toast.add({
                      severity: "success",
                      summary: "Success",
                      detail: response.data.message,
                      life: 3000,
                    });
                    this.vehicleDriverList();
                    this.display3=false;
                    this.display2=false;
                  }
                  else
                  {
                    this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: response.data.message,
                    life: 3000,
                  });
                  return false;
                  }
                }
              )
    },
    search_driver()
    {
        var data={user_id:this.owner_id,
            search:this.search_number,
            type:"mobile",
            vehicle_id:this.vehicle_id};
            this.search_loading=true;
      var promise = apis.searchDriverVehicleAdd(data);
      promise
        .then(response => {
          this.search_loading=false;
            this.driver_list1=response.data.data;
            // alert(search_result.length);
            if(this.driver_list1.length==0)
            {
                this.search_status=true;
            }
            else{
                this.search_status=false;
            }
          }
        )
    },
    setpath(image)
    {
        this.image_path=image;
        
    },
    toggle(event,data) {
        this.image_path=data;

        this.$refs.op.toggle(event);
    },
    open(data) {
      this.display = true;
      this.vehicle_details=data;
      this.vehicle_documents=data.vehicledetails[0].documents;
    },
    open2() {
      this.driver_list1=[];
      this.display2 = true;
      
    },
    add_driver_modal(event,data) {
      this.driver_id=data._id;
      this.display3 = true;
      
    },
    async open1(data) {
      
      this.vehicle_id=data.vehicle_id;
      this.owner_id=data.owner_id;
      
      await this.vehicleDriverList();
      this.display1 = true;
        
    //   this.vehicle_details=data;
    //   this.vehicle_documents=data.vehicledetails[0].documents;
    },
    close() {
      this.display = false;
        this.display1 = false;
        this.display2 = false;
    
    },
    close2() {
        this.display2 = false;
    
    },
    close3() {
        this.display3 = false;
    
    },
    vehicleDriverList() {
        // alert("here");
        this.driver_list=[];
        this.search_loading1=true;
      var data={owner_id:this.owner_id,
    vehicle_id:this.vehicle_id};
      var promise = apis.vehicleDriverList(data);
      promise
        .then(response => {
          this.search_loading1=false;
            this.driver_list=response.data.data;
          }
        )
    },
    
    D_M_Y_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "-" + mm + "-" + yyyy;
        // alert(dd)
        if (dd.toString() != "NaN") {
          //this.receipt_new_date = new_date;
          return new_date;
        } else {
          return date_parm;
        }
      }
    },
    time_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        // let ampm = '';
            var mid='AM';
            if(hours==0){ //At 00 hours we need to show 12 am
            hours=12;
            }
            else if(hours>12)
            {
            hours=hours%12;
            mid='PM';
            }

        let new_date = ("0" +hours).slice(-2) + ":" + ("0" +minutes).slice(-2) + " " + mid;
        // alert(dd)
        if (hours.toString() != "NaN") {
          //this.receipt_new_date = new_date;
          return new_date;
        } else {
          return date_parm;
        }
      }
    },
  },
  mounted() {
    // alert('here');
  },
};
</script>